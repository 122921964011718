<template>
  <section>
    <album-cover
      ref="wdAlbumCover"
      :item="info"
    />
    <album-header
      ref="wdAlbumHeader"
      :item="info"
      @add-works="onAddAlbumImage"
    />
    <v-divider />
    <album-images ref="wdAlbumImages" />
  </section>
</template>

<script>
  import app from '@/api/co.app'
  import lib from '@/api/co.lib'
  import web from '@/api/web/co.web'
  export default {
    metaInfo: { title: '我的影集' },
    components: {
      AlbumCover: () => import('@/pages/sections/album/AlbumCover.vue'),
      AlbumHeader: () => import('@/pages/sections/album/AlbumHeader.vue'),
      AlbumImages: () => import('@/pages/sections/album/AlbumImages.vue'),
    },
    data () {
      return {
        info: {}
      }
    },
    created () {
      app.on(app.event.ALBUM_COVER_CHANGE, this.onChangeAlbumCover)
      app.on(app.event.ALBUM_INFO_CHANGE, this.onChangeAlbumInfo)

      const params = JSON.parse(lib.cookie.get('Album'))
      const albumId = params.albumId || ''
      this.getAlbum(albumId)
    },
    destroyed () {
      app.off(app.event.ALBUM_COVER_CHANGE)
      app.off(app.event.ALBUM_INFO_CHANGE)
    },
    methods: {
      onChangeAlbumCover (ev) {
        const wd = this.$refs.wdAlbumCover
        if (wd) {
          wd.reset(ev)
        }
      },
      onChangeAlbumInfo (ev) {
        this.info = Object.assign({}, this.info, ev)
      },
      onAddAlbumImage (imgList) {
        const wd = this.$refs.wdAlbumImages
        if (wd) {
          wd.add(imgList)
        }
      },
      getAlbum (albumId) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.info = res.data
          }
        }

        web.album.getDetail({
          id: albumId,
          executed
        })
      }
    }
  }
</script>
